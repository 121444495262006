import React from 'react';

import { IChallengePageProps } from './interfaces';
import { useSettings } from '@wix/tpa-settings/react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { st, classes } from './Widget.st.css';
import settingsParams from '../settingsParams';
import { IChallengeSettings } from '../Settings/challengeSettings/challengeSettings.types';
import { withProviders } from '../../../contexts/main/withProviders';
import { FCWithChildren } from '@wix/challenges-web-library';
import VisitorPage from './views/VisitorPage/VisitorPage';
import { useIsFullWithInViewer } from '../../../services/hooks/useIsFullWidthInViewer';

export const ChallengePage: FCWithChildren<IChallengePageProps> = (props) => {
  const { t } = useTranslation();
  const { isViewer, isMobile, language } = useEnvironment();
  const isStretched = useIsFullWithInViewer();

  const settings = useSettings();
  const pageRef = React.useRef<HTMLDivElement>();

  const settingsProp: IChallengeSettings = {} as any;
  Object.keys(settingsParams).map((key) => {
    settingsProp[key] = settings.get(settingsParams[key]);
  });

  const { user, challengeData } = props;

  React.useEffect(() => {
    props?.hideLoader?.();
  }, []);

  React.useEffect(() => {
    if (!user.loggedIn && !challengeData?.challenge?.settings && isViewer) {
      props.promptLogin();
    }
  }, [props, user, isViewer, challengeData?.challenge?.settings]);

  if (props.isForcedBlankPage) {
    return null;
  } else if (!challengeData?.challenge?.settings && isViewer) {
    return <p className={classes.noAccess}>{t('challenge.no-access')}</p>;
  }
  return (
    <div
      ref={pageRef}
      data-hook="program-page-main"
      className={st(classes.root)}
    >
      <div data-hook="challenge-page">
        <VisitorPage
          t={t}
          lng={language}
          {...props}
          settings={settingsProp as any}
          isMobile={isMobile}
          isStretched={isStretched}
        />
      </div>
    </div>
  );
};

ChallengePage.displayName = 'ChallengePageContainer';

export default withProviders(ChallengePage);
